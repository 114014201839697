import React, { useMemo } from 'react';
import { DataTable } from '@nintendo/data-table';
import { Button } from 'react-bootstrap';
import { Accept } from 'react-dropzone';
import UploadModal from './UploadModal';

const FILE_TYPES = [
    {
        type: 'SDM Order File',
        extensions: '.xlsx',
        acceptTypes: {
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
        },
    },
];

const Upload = () => {
    const columns = [
        {
            Header: 'File Type',
            accessor: 'type',
            sortable: false,
        },
        {
            Header: 'Document Type',
            accessor: 'extensions',
            sortable: false,
        },
        {
            Header: '',
            accessor: 'acceptTypes',
            Cell: (cellProps: {
                row: {
                    type: string;
                    extensions: string;
                    acceptTypes: Accept;
                };
            }) =>
                useMemo(
                    () => (
                        <UploadModal
                            toggleButton={
                                <Button variant="link" className="text-dark py-0">
                                    <i className="far fa-file-import" /> Upload File
                                </Button>
                            }
                            type={cellProps.row.type}
                            extensions={cellProps.row.extensions}
                            acceptTypes={cellProps.row.acceptTypes}
                        />
                    ),
                    [cellProps],
                ),
            sortable: false,
            width: 155,
        },
    ];

    return (
        <div className="container">
            <div className="row row-page-header">
                <div className="col-md-12">
                    <h1 className="bordered">Upload Files</h1>
                </div>
            </div>
            <DataTable
                columns={columns}
                data={FILE_TYPES}
                minRows={0}
                className="-striped -highlight"
                showPagination={false}
            />
        </div>
    );
};

export default Upload;
