import { LoruleRequestConfig } from '@galaxy/lorule-react-commons';
import React from 'react';
import { IPublicClientApplication } from '@azure/msal-browser';

const ConfigContext = React.createContext<LoruleRequestConfig>({
    msalInstance: {} as IPublicClientApplication,
    scopes: [],
    url: '',
});

export default ConfigContext;
