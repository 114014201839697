import React, { useContext, useMemo } from 'react';
import { DataTable } from '@nintendo/data-table';
import { useAsync } from 'react-commons';
import moment from 'moment';
import ConfigContext from '../../contexts/ConfigContext';
import ErrorBlock from '../common/ErrorBlock';
import { DATE_TIME_FORMAT, LOCAL_TIMEZONE } from '../../utils/Constants';
import Spinner from '../common/Spinner';
import { convertISO8601ToTimezone } from '../../utils/DisplayUtils';
import './History.css';
import { UploadHistoryType } from '../../api/historyApiTypes';
import { getUploadHistory } from '../../api/historyApi';

const History = () => {
    const config = useContext(ConfigContext);

    const defaultState: Array<UploadHistoryType> = [];
    const { value, error, loading } = useAsync<Array<UploadHistoryType>>(
        () => getUploadHistory(config),
        defaultState,
    );

    if (error) {
        return typeof error === 'object' ? (
            <ErrorBlock errorMessage="Network Error!" />
        ) : (
            <ErrorBlock errorMessage={error} />
        );
    }

    const getUploadStatus = (uploadHistory: UploadHistoryType) => {
        if (uploadHistory.successful) {
            return 'SUCCESS';
        }
        if (uploadHistory.processing) {
            const someTimeAgo = moment().subtract(15, 'minutes');
            if (moment.tz(uploadHistory.uploadDate, 'UTC').isBefore(someTimeAgo)) {
                return 'FAILED';
            }
            return 'PROCESSING';
        }
        return 'FAILED';
    };

    const history = value.map((uploadHistory) => ({
        fileName: uploadHistory.fileName,
        uploadDate: uploadHistory.uploadDate,
        uploadedBy: uploadHistory.uploadedBy,
        uploadStatus: getUploadStatus(uploadHistory),
    }));

    const columns = [
        {
            Header: 'File Name',
            accessor: 'fileName',
            minWidth: 230,
        },
        {
            Header: 'Upload Date',
            accessor: 'uploadDate',
            minWidth: 120,
            Cell: (cellProps: { value: string }) =>
                convertISO8601ToTimezone(cellProps.value, LOCAL_TIMEZONE, DATE_TIME_FORMAT),
        },
        {
            Header: 'Uploaded By',
            accessor: 'uploadedBy',
            minWidth: 100,
        },
        {
            Header: 'Status',
            accessor: 'uploadStatus',
            filterDropDown: true,
            Cell: (cellProps: { value: string }) =>
                useMemo(
                    () => (
                        <strong
                            className={`history-${
                                cellProps.value
                                    ? cellProps.value.replace(/\s+/g, '-').toLowerCase()
                                    : ''
                            }`}
                        >
                            {cellProps.value ? ` ${cellProps.value}` : 'N/A'}
                        </strong>
                    ),
                    [cellProps],
                ),
        },
    ];

    return !loading ? (
        <div className="container">
            <div className="row row-page-header">
                <div className="col-md-12">
                    <h1 className="bordered">File Upload History</h1>
                </div>
            </div>
            <DataTable columns={columns} data={history} className="-striped -highlight" />
        </div>
    ) : (
        <Spinner />
    );
};

export default History;
