import React, { useState, useCallback } from 'react';
import { Modal, ModalProps } from 'react-bootstrap';

export interface NinModalProps extends Partial<ModalProps> {
    button: React.ReactElement;
    render?: (toggle: () => void) => React.ReactNode;
}

const NinModal = (props: NinModalProps) => {
    const [show, setShow] = useState(false);

    const { button, render } = props;

    const toggleModal = () => {
        if (show) {
            setShow(false);
            onModalClose();
        } else {
            setShow(true);
            if (props.onShow) {
                props.onShow();
            }
        }
    };

    const onModalClose = () => {
        if (props.onHide) {
            props.onHide();
        }
    };

    const closeModal = useCallback(() => {
        onModalClose();
        setShow(false);
    }, []);

    const toggleButton = React.cloneElement(button, { onClick: toggleModal });
    return (
        <>
            {toggleButton}
            <Modal
                {...{
                    ...props,
                    onHide: closeModal,
                    show,
                }}
            >
                {render && render(toggleModal)}
            </Modal>
        </>
    );
};

export default NinModal;
