import React from 'react';
import moment from 'moment-timezone';
import { toast } from 'react-toastify';
import { RouteComponentProps } from 'react-router-dom';
import { DATE_TIME_FORMAT, LOCAL_TIMEZONE } from './Constants';

export interface IIndexable {
    [key: string]: any;
}

export const emptyStringIfNull = (itemObject: any, attr: string) => {
    if (
        itemObject !== null &&
        itemObject !== undefined &&
        (itemObject as IIndexable)[attr] !== null &&
        (itemObject as IIndexable)[attr] !== undefined
    ) {
        return (itemObject as IIndexable)[attr].toString() as string; // use as string to make type-check happy
    }
    return '';
};

export const convertEmptyStringToDashes = (value: string | null | number, date?: boolean) => {
    if (date && value) {
        value = convertISO8601ToTimezone(value.toString(), LOCAL_TIMEZONE, DATE_TIME_FORMAT);
    }

    if (value === undefined || value === null || value === '') {
        return '--';
    }
    return value;
};

export const createRedirectToast = (
    url: string,
    text: string,
    buttonText: string,
    routerProps: RouteComponentProps,
) => (
    <div>
        <strong>{text}</strong>
        <button
            className="btn btn-link no_left_padding"
            type="button"
            onClick={() => routerProps.history.push(url)}
        >
            {buttonText}
        </button>
    </div>
);

export const createTextToast = (text: string) => <strong>{text}</strong>;

export const sendToast = (content: React.ReactNode, timeToClose: number, fail?: boolean) => {
    if (toast) {
        if (fail) {
            toast.error(content, { hideProgressBar: true, autoClose: timeToClose });
        } else {
            toast.success(content, { hideProgressBar: true, autoClose: timeToClose });
        }
    }
};

const DEFAULT_FORMAT = 'MM/DD/YYYY hh:mm A z';

export const convertISO8601ToTimezone = (date: string, timeZone: string, format = DEFAULT_FORMAT) =>
    moment.tz(date, timeZone).format(format);

export const convertStringToDate = (date: string, format = DEFAULT_FORMAT) => {
    const dateObj = moment(date, format).toDate();
    return dateObj.valueOf() ? dateObj : null;
};
