/* React-table is installed by data-table. By doing it this way, we prevent any problems with
Node 16 install behavior / incorrect react-table version */
/* eslint import/no-extraneous-dependencies: 0 */

import React from 'react';
import ReactDOM from 'react-dom/client';
import {
    EventType,
    PublicClientApplication,
    Configuration,
    RedirectRequest,
} from '@azure/msal-browser';
import 'react-table/react-table.css';
import 'flatpickr/dist/themes/material_red.css';
import '@nintendo/data-table/default-noa.css';
import './src/resources/css/sitestyles.css';
import 'react-toastify/dist/ReactToastify.css';
import FileUploadApp from './src/js/components/FileUploadApp';

declare global {
    interface Window {
        msalConfig: Configuration;
        nfmApiRequest: RedirectRequest;
        nfmApiUrl: string;
    }
}

const { msalConfig } = window;

export const msalInstance = new PublicClientApplication(msalConfig);

const accounts = msalInstance.getAllAccounts();

if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((event) => {
    if (
        event.eventType === EventType.LOGIN_SUCCESS &&
        event.payload &&
        'account' in event.payload &&
        event.payload.account
    ) {
        const { account } = event.payload;
        msalInstance.setActiveAccount(account);
    }
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(<FileUploadApp msalInstance={msalInstance} />);
