import React, { useContext, useState } from 'react';
import { LoruleRequestConfig } from '@galaxy/lorule-react-commons';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { ResponseError, SubmitButton, FileField } from '@nintendo/forms';
import { Accept } from 'react-dropzone';
import ConfigContext from '../../contexts/ConfigContext';
import NinModal from '../common/NinModal';
import uploadFile from '../../api/uploadApi';

export interface UploadModalProps {
    type: string;
    extensions: string;
    acceptTypes: Accept;
    toggleButton: JSX.Element;
    onSubmit?: () => void;
}

const submitUpload = async (
    config: LoruleRequestConfig,
    type: string,
    fileToUpload: File | null,
    resetForm: () => void,
    setSubmitting: (isSubmitting: boolean) => void,
    toggleModal: () => void,
    setResponseError: (errorMessage: string) => void,
) => {
    try {
        resetForm();
        toggleModal();

        if (fileToUpload) {
            await uploadFile(config, type, fileToUpload);
        }

        return true;
    } catch (error) {
        if (error instanceof Error) {
            setResponseError(`failed:  ${error.message}`);
        }
        setSubmitting(false);
        return false;
    }
};

const UploadModal = (props: UploadModalProps) => {
    const config = useContext(ConfigContext);
    const [responseError, setResponseError] = useState('');

    const FILE_SIZE = 250 * 1024 * 1024; // 250M

    const validationSchema = Yup.object().shape({
        uploadFile: Yup.mixed()
            .required('A file is required')
            .test(
                'fileSize',
                'Document cannot be over 250MB',
                (value) => value && value.size <= FILE_SIZE,
            )
            .test(
                'fileName',
                'Document name must consist of only ASCII characters',
                (value) => value && /^((?![<>:"/\\|?*])[\x20-\x7F])*$/.test(value.name),
            )
            .test(
                'fileNameLength',
                'Document name must less than 200 characters',
                (value) => value && value.name.length <= 200,
            ),
    });

    return (
        <NinModal
            size="lg"
            button={props.toggleButton}
            render={(toggleModal: () => void) => (
                <Formik
                    initialValues={{ uploadFile: null }}
                    validationSchema={validationSchema}
                    onSubmit={async (values, { resetForm, setSubmitting }) => {
                        const submitSuccessful = await submitUpload(
                            config,
                            props.type,
                            values.uploadFile,
                            resetForm,
                            setSubmitting,
                            toggleModal,
                            setResponseError,
                        );
                        setResponseError('');
                        if (submitSuccessful) {
                            if (props.onSubmit) {
                                props.onSubmit();
                            }
                            if (toast) {
                                toast.success(<>File uploaded successfully</>, {
                                    hideProgressBar: true,
                                });
                            }
                        }
                    }}
                    render={({
                        isSubmitting,
                        touched,
                        errors,
                        setFieldValue,
                        setFieldTouched,
                        dirty,
                    }) => (
                        <div className="modal-content" data-testid="event-modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Upload {props.type}</h4>
                                <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true" onClick={toggleModal}>
                                        &times;
                                    </span>
                                </button>
                            </div>

                            <Form className="form-horizontal" data-testid="event-modal-form">
                                <div className="modal-body">
                                    <ResponseError errorMessage={responseError} />

                                    <FileField
                                        dropZoneText="click here to pick file to upload"
                                        label={`Upload File (${props.extensions})`}
                                        name="uploadFile"
                                        touched={touched.uploadFile}
                                        errors={errors.uploadFile}
                                        isRequired
                                        onChange={(file?: File) => {
                                            setFieldTouched('uploadFile', true);
                                            if (file) {
                                                setFieldValue('uploadFile', file);
                                            }
                                        }}
                                        acceptTypes={props.acceptTypes}
                                        dropZoneOptions={{ noDrag: false }}
                                        testId="upload-file"
                                    />
                                    <small className="form-text text-muted document-limit-text">
                                        Document cannot be over 250MB
                                    </small>
                                </div>

                                <div className="modal-footer">
                                    <button
                                        type="button"
                                        id="eventModalCloseButton"
                                        className="btn btn-outline-secondary mr-auto"
                                        onClick={toggleModal}
                                        disabled={isSubmitting}
                                    >
                                        Close
                                    </button>
                                    <SubmitButton
                                        isSubmitting={isSubmitting}
                                        disabled={!dirty}
                                        testId="event-modal-submit-button"
                                        buttonText="Upload File"
                                    />
                                </div>
                            </Form>
                        </div>
                    )}
                />
            )}
        />
    );
};

export default UploadModal;
