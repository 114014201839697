import React, { useMemo } from 'react';
import 'regenerator-runtime/runtime.js';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { LoruleApplication, LoruleMenuConfig } from '@galaxy/lorule-react-commons';
import { ToastContainer } from 'react-toastify';
import ConfigContext from '../contexts/ConfigContext';
import Upload from './upload/Upload';
import History from './history/History';

interface NfmApplicationProps {
    msalInstance: PublicClientApplication;
}

const NfmAppInternal = () => {
    const uploadTab: LoruleMenuConfig = {
        name: 'Upload Files',
        roles: null,
        components: [
            {
                name: 'Upload Files',
                path: '/upload',
                exact: true,
                roles: null,
                component: <Upload />,
            },
        ],
    };

    const historyTab: LoruleMenuConfig = {
        name: 'Upload History',
        roles: null,
        components: [
            {
                name: 'Upload History',
                path: '/history',
                roles: null,
                component: <History />,
            },
        ],
    };

    const componentTabs: Array<LoruleMenuConfig> = [];
    componentTabs.push(uploadTab);
    componentTabs.push(historyTab);

    const tabs = componentTabs.map((tab) => ({
        ...tab,
        components: tab.components.filter((component) => component.path),
    }));

    return (
        <>
            <ToastContainer />
            <LoruleApplication tabs={tabs} appName="File Management" defaultPath="/upload" />
        </>
    );
};

const NfmApp = ({ msalInstance }: NfmApplicationProps) => {
    const { nfmApiRequest, nfmApiUrl } = window;
    return (
        <MsalProvider instance={msalInstance}>
            <ConfigContext.Provider
                value={useMemo(
                    () => ({
                        msalInstance,
                        scopes: nfmApiRequest.scopes,
                        url: nfmApiUrl,
                    }),
                    [],
                )}
            >
                <NfmAppInternal />
            </ConfigContext.Provider>
        </MsalProvider>
    );
};

export default NfmApp;
