import React from 'react';
import Loading from 'react-loading';
import './Spinner.css';

type SpinnerProps = {
    'data-testid'?: string;
};

const Spinner = (props: SpinnerProps) => (
    <div
        className="SpinnerView"
        data-testid={props['data-testid'] ? props['data-testid'] : 'loading'}
    >
        <Loading color="#E8001E" />
    </div>
);

export default Spinner;
