import React from 'react';

type Props = {
    errorMessage: string;
};

const ErrorBlock = (props: Props) => (
    <div className="alert alert-danger" role="alert">
        {props.errorMessage}
    </div>
);

export default ErrorBlock;
