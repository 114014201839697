import { LoruleRequestConfig } from '@galaxy/lorule-react-commons';
import { callWebUtils, commonHeaders } from './commonApiUtil';

export const uploadFile = async (config: LoruleRequestConfig, type: string, file: File) => {
    const location = await callWebUtils<{ location: string }>(
        config,
        `/upload-url?fileName=${file.name}`,
        'GET',
        null,
        commonHeaders,
    );
    if (location) {
        return fetch(location.location, {
            method: 'PUT',
            headers: {},
            cache: 'no-cache',
            body: file,
        });
    }
    return null;
};

export default uploadFile;
