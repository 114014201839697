import { LoruleRequestConfig, WebUtils, LoruleResponse } from '@galaxy/lorule-react-commons';

export const buildConfig = (config: LoruleRequestConfig, path: string) => ({
    ...config,
    url: `${config.url}${path.startsWith('/') ? path : `/${path}`}`,
});

/**
 * Wrapper method for common WebUtils calls
 */
export const callWebUtils = async <T>(
    config: LoruleRequestConfig,
    path: string,
    httpMethod: string,
    postData: string | null,
    headers: Record<string, string> | null,
) => {
    const result = await WebUtils.requestWithHeaders<T>(
        buildConfig(config, path),
        httpMethod,
        postData,
        headers,
    );

    if (result && result.status !== 204) {
        const body = await result.json();
        checkForErrors(body);
        return body;
    }
    return null;
};

export const checkForErrors = (result: any) => {
    if (result.errorMessage) {
        throw new Error(result.errorMessage);
    } else if (result.code && result.message) {
        throw new Error(result.message);
    }
};

export const commonHeaders = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
};

export const triggerDownload = async (response: LoruleResponse<unknown>) => {
    const documentBody: Element | null = document.body;
    if (documentBody) {
        const blob = await response.blob();
        const blobUrl = window.URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = blobUrl;

        const contentDisposition = response.headers.get('Content-Disposition');
        if (contentDisposition) {
            const [, fileName] = contentDisposition.split('filename=');
            a.download = decodeURIComponent(fileName.replace(/\+/g, ' '));
        }

        documentBody.appendChild(a);
        a.click();
        documentBody.removeChild(a);
    }
};
