export const LOCAL_TIMEZONE = 'America/Los_Angeles';
export const UTC = 'UTC';
export const DATE_ONLY_FORMAT = 'MM/DD/YYYY';
export const DATE_TIME_FORMAT = 'MM/DD/YYYY hh:mm:ss A';
export const PST_DAY_START = '00:00:00';
export const PST_DAY_END = '23:59:59';

export const NOA = 'NOA';
export const USD = 'USD';
export const CAD = 'CAD';

export const CURRENCY_CODES = ['ARS', 'BRL', 'CAD', 'CLP', 'COP', 'MXN', 'PEN', 'USD'];

const DCA_USER = 'dca_user';
const DCA_SUPER_USER = 'dca_superuser';
const DCA_ADMIN = 'dca_admin';
export const DCA_MAINTENANCE_SCOPES = [DCA_ADMIN, DCA_SUPER_USER];
export const DCA_EXECUTION_SCOPES = [DCA_ADMIN, DCA_SUPER_USER, DCA_USER];

export const MEM_GL_REGEX =
    /^[0-9]{3}\.[0-9]{4}\.[0-9]{5}\.[0-9]{3}\.[0-9a-zA-Z]{2}\.[0-9a-zA-Z]{2}\.[0-9a-zA-Z]{3}$/;
export const MEM_COUNTRIES = ['US', 'CA', 'MX', 'BR', 'CL', 'CO', 'PE', 'AR'];
export const MEM_LEDGERS = ['NOA', 'NOCL'];
export const MEM_CHANNELS = ['DRI', 'POSA', 'Live', 'eShop', 'Magento', 'B2BFE-RETL', 'B2BFE-DTC'];

export const TOAST_STAY_TIME = 5000;

export const DATE_PICKER_OPTION = {
    options: {
        altInputClass: 'form-control',
        altFormat: DATE_ONLY_FORMAT,
        enableTime: false,
        dateFormat: DATE_ONLY_FORMAT,
    },
};
